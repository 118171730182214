import React, { useState, useEffect } from "react";
import classnames from "classnames";
import { Link } from "gatsby";
import logo from "../img/logo.svg";
import { SITE_NAME } from "../utils/constants";
import "./Navbar.scss";

const Navbar = ({ className }) => {
  const [active, setActive] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const logit = (e) => {
    setIsScrolled(e.target.scrollTop > 64);
  };

  useEffect(() => {
    const watchScroll = () => {
      document.body.addEventListener("scroll", logit);
    };
    watchScroll();
    return () => {
      document.body.removeEventListener("scroll", logit);
    };
  }, []);

  return (
    <nav
      className={classnames("Navbar", className, {
        "is-scrolled": isScrolled,
        "is-active": active,
      })}
      role="navigation"
      aria-label="main-navigation"
    >
      <div className="Navbar__TopNav">
        <Link to="/" title={"Logo"}>
          <img
            src={logo}
            alt={`logo ${SITE_NAME}`}
            style={{ width: "175px" }}
          />
        </Link>
        {/* Hamburger menu */}
        <div
          className={classnames("Navbar__Burger", {
            "is-active": active,
          })}
          onClick={() => {
            setActive(!active);
          }}
        >
          <span
            className={classnames("Navbar__BurgerItem", {
              "is-active": active,
            })}
          />
          <span
            className={classnames("Navbar__BurgerItem", {
              "is-active": active,
            })}
          />
          <span
            className={classnames("Navbar__BurgerItem", {
              "is-active": active,
            })}
          />
        </div>
      </div>
      <div className={classnames("Navbar__menuList", { "is-active": active })}>
        <Link className="Navbar__menuItem button-pill" to="/">
          Accueil
        </Link>
        <Link className="Navbar__menuItem button-pill" to="/actualites">
          Actualités
        </Link>
        <Link className="Navbar__menuItem button-pill" to="/equipe">
          Équipe
        </Link>
        <Link className="Navbar__menuItem button-pill" to="/pathologies">
          Pathologies
        </Link>
        <Link className="Navbar__menuItem button-pill" to="/mon-intervention">
          Mon intervention
        </Link>
        <Link
          className="Navbar__menuItem button-pill"
          to="/informations-pratiques"
        >
          Infos pratiques
        </Link>
      </div>
    </nav>
  );
};

export default Navbar;
