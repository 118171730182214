import React from "react";
import { Link } from "gatsby";
import "./Footer.scss";
import { SITE_NAME } from "../utils/constants";

const Footer = () => {
  return (
    <footer className="Footer">
      <div className="Footer__LegalMentions">
        <div>© 2020 {SITE_NAME}</div>
        <div>&nbsp;-&nbsp;</div>
        <Link to="/legal-mentions"> mentions légales</Link>
        <div>&nbsp;-&nbsp;</div>
        <a href="tel:0183622375" className="Footer__button">
          01.83.62.23.75
        </a>
      </div>
    </footer>
  );
};

export default Footer;
